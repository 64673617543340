import { FC } from 'react';
import IconProps from './IconProps';

const FolderIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.625 0.75C1.53075 0.75 0.625 1.65575 0.625 2.75V14.75C0.625 15.8443 1.53075 16.75 2.625 16.75H18.625C19.7193 16.75 20.625 15.8443 20.625 14.75V4.75C20.625 3.65575 19.7193 2.75 18.625 2.75H10.625L8.625 0.75H2.625ZM2.625 2.75H7.79688L9.79688 4.75H18.625V14.75H2.625V2.75Z"
        fill="#3E2234"
      />
    </svg>
  );
};

export default FolderIcon;
